import React from 'react';

import AdminLayout from 'Pages/Admin/AdminLayout';

import {PrimaryButton} from 'Components/Button';
import {FormHandler, Input, IsTranslatableWrapper, Toggle, Select} from 'Components/Form';
import {Alert, Loading} from 'Components/Partials';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';

import FieldApi from 'Services/Api/Admin/Cms/Elements/Field';
import {translationFromJson} from 'Services/TranslationHelpers2';
import {capitalizeFirstLetter} from 'Services/BaseHelpers';

class AdminCmsElementFieldsStore extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = 'Input ' + (this.props.field ? 'updated' : 'created') + ' successfully';

    /**
     * @var state
     */
    state = {
        types: null,
        validationOptions: [
            {
                label: 'required',
                value: 'required'
            },
            {
                label: 'nullable',
                value: 'nullable'
            }
        ],
        multipleChoiceValuesOptions: null
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        await this.loadAvailableFieldTypes();

        if (this.props.field) {
            this.loadField();
        } else {
            this.props.setInitialValues({
                identifier: '',
                label: null,
                type: '',
                validation: null,
                show_in_index_table: false,
                searchable: false,
                instructions: '',
                file_accept: '',
                multiple_choice_values: null,
                admin_only: false
            });
        }
    }

    /**
     * @method loadAvailableFieldTypes
     */
    loadAvailableFieldTypes = async () => {
        const response = await FieldApi.indexAvailableFieldTypes();

        this.setState({
            types: response.types.map((type, key) => {
                let label = type.replace(/_/g, ' ');

                if (type === 'boolean') {
                    label = 'boolean (true/false)';
                }

                return {
                    label: capitalizeFirstLetter(label),
                    value: type
                };
            })
        })
    }

    /**
     * @method loadField
     */
    loadField = async () => {
        const {field} = this.props;

        this.props.setForm(field);

        this.setState({
            validationOptions: field.validation ? field.validation.map((value, key) => {
                return {
                    label: value,
                    value: value
                };
            }) : null,
            multipleChoiceValuesOptions: field.multiple_choice_values ? field.multiple_choice_values.map((value, key) => {
                return {
                    label: value,
                    value: value
                };
            }) : null
        });
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<*>}
     */
    handleSubmit = (form) => {
        const {field} = this.props;

        form = {
            ...form,
            cms_element_id: this.props.element_id,
        }

        if (field) {
            return FieldApi.patch(field.id, form);
        } else {
            return FieldApi.post(null, form);
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {types, validationOptions, multipleChoiceValuesOptions} = this.state;
        const {field, form, working, alert, handleInput, getFieldError, handleSubmit} = this.props;

        let languages = Object.entries(window.base.languages).map((value, key) => {
            return value[0];
        });

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {field ? 'Update' : 'Create'} Input
                </ModalHeader>

                <form onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, (field ? false : true))}>
                    <ModalBody>
                        {alert !== null && (<Alert {...alert} />)}

                        <div className="grid gap-4 border-0">
                            <div>
                                 <IsTranslatableWrapper
                                    label="Label"
                                    value={form.label}
                                    id="label"
                                    error={getFieldError('label')}
                                    onChange={(v) => handleInput('label', v)}
                                    supported_languages={languages}
                                    childComponent="Input"
                                    childProps={{}}
                                />
                            </div>

                            <div>
                                <Input
                                    label="Identifier"
                                    value={form.identifier}
                                    id="identifier"
                                    error={getFieldError('identifier')}
                                    onChange={(v) => handleInput('identifier', v)}
                                />
                            </div>

                            {!field &&
                                <div>
                                    <Select
                                        label="Type"
                                        value={form.type}
                                        error={getFieldError('type')}
                                        onChange={(v) => handleInput('type', v)}
                                        options={types}
                                    />
                                </div>
                            }

                            <div>
                                <Select
                                    label="Validation (Consult a Dev)"
                                    value={form.validation}
                                    error={getFieldError('validation')}
                                    onChange={v => handleInput('validation', v)}
                                    placeholder="Type here to create a new value..."
                                    options={validationOptions}
                                    isMulti
                                    isCreatable
                                />
                            </div>

                            <div>
                                <Toggle
                                    label="Display In Records Table"
                                    labelPlacement="top"
                                    value={form.show_in_index_table}
                                    id="show_in_index_table"
                                    error={getFieldError('show_in_index_table')}
                                    onChange={(v) => handleInput('show_in_index_table', v)}
                                />
                            </div>

                            <div>
                                <Toggle
                                    label="Searchable In Records Table"
                                    labelPlacement="top"
                                    value={form.searchable}
                                    id="searchable"
                                    error={getFieldError('searchable')}
                                    onChange={(v) => handleInput('searchable', v)}
                                />
                            </div>

                            <div>
                                <Input
                                    label="Instructions (Optional)"
                                    value={form.instructions}
                                    id="instructions"
                                    error={getFieldError('instructions')}
                                    onChange={(v) => handleInput('instructions', v)}
                                    instructions="Instructions will be displayed like this."
                                />
                            </div>

                            {form.type === 'file' &&
                                <div>
                                    <Input
                                        label="File Accept (Optional)"
                                        value={form.file_accept}
                                        id="file_accept"
                                        error={getFieldError('file_accept')}
                                        onChange={(v) => handleInput('file_accept', v)}
                                    />
                                </div>
                            }

                            {form.type === 'multiple_choice_single_value' &&
                                <div>
                                    <Select
                                        label="Multiple Choice Values (Consult a Dev)"
                                        value={form.multiple_choice_values}
                                        error={getFieldError('multiple_choice_values')}
                                        onChange={v => handleInput('multiple_choice_values', v)}
                                        placeholder="Type here to create a new value..."
                                        options={multipleChoiceValuesOptions}
                                        isMulti
                                        isCreatable
                                    />
                                </div>
                            }

                            <div>
                                <Toggle
                                    label="Admin Only"
                                    labelPlacement="top"
                                    value={form.admin_only}
                                    id="admin_only"
                                    error={getFieldError('admin_only')}
                                    onChange={(v) => handleInput('admin_only', v)}
                                />
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter alignment="center">
                        <PrimaryButton
                            text={field ? 'Update' : 'Create'}
                            working={working}
                        />
                    </ModalFooter>
                </form>
            </Modal>
        )
    }
}

export default FormHandler(AdminCmsElementFieldsStore);
