import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';
import {faQuestion} from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faEye } from "@fortawesome/free-regular-svg-icons/faEye";

import {Loading} from 'Components/Partials';
import {ModalTrigger} from 'Components/Modal';
import CreateCmsElementRecordModal from 'Components/Partials/Modals/CreateCmsElementRecordModal';
import CreateTimesheetModal from 'Components/HumanResources/Timesheets/CreateTimesheetModal';
import QueryTimesheetModal from 'Components/HumanResources/Timesheets/QueryTimesheetModal';
import ApproveHoliday from "Components/HumanResources/Timesheets/ApproveHoliday";
import ShowTimesheetModal from "Components/HumanResources/Timesheets/ShowTimesheetModal";

import ToDoListApi from 'Services/Api/ToDoList/ToDoList';
import RecordApi from 'Services/Api/Cms/Elements/Record';
import {translation} from 'Services/TranslationHelpers';
import AdminTimesheetsApi from 'Services/Api/Admin/HumanResources/Timesheets';
import TimesheetsApi from 'Services/Api/HumanResources/Timesheets';

import { User } from 'Services';

export default class ToDoListIndexTable extends React.Component {
    /**
     * @var state
     */
    state = {
        working: true,
        list: [],
        showAll: false,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.loadList();

        RecordApi.on('create', this.loadList);

        if (User.data.is_admin) {
            AdminTimesheetsApi.on('updated', this.loadList);
        }

        TimesheetsApi.on('updated', this.loadList);
    }

    /**
     * @method handleToggleShowAll
     */
    handleToggleShowAll = () => this.setState({
        showAll: !this.state.showAll,
    });

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        RecordApi.removeListener('create', this.loadList);

        if (User.data.is_admin) {
            AdminTimesheetsApi.removeListener('updated', this.loadList);
        }

        TimesheetsApi.removeListener('updated', this.loadList);
    };

    /**
     * @method loadList
     */
    loadList = async () => {
        this.setState({
            working: true,
        });

        const response = await ToDoListApi.get(null,);

        if (response.success) {
            this.setState({
                working: false,
                list: response.data,
            });
        }
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const { working, list, showAll } = this.state;

        return (
            <div>
                {working && (<Loading />)}

                {!working && (
                    <>
                        <div
                            onClick={this.loadList}
                            className="text-right cursor-pointer font-bold text-light-blue-700 pr-4 pt-4"
                        >
                            <FontAwesomeIcon
                                icon={faSync}
                                className="text-gray-400 hover:text-gray-600 transition duration-200"
                            />
                        </div>

                        {!list || list.length === 0 && (
                            <div className="text-center text-light-blue-700 mt-10 pb-6">
                                {translation('to_do_list', 'empty')}
                            </div>
                        )}

                        {list?.length !== 0 && (
                            <div className="overflow-x-auto">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className="border-b border-gray-200">
                                            <th className="px-4 py-2 text-left" onClick={() => this.handleOrderBy('first_name')}>
                                                Title
                                            </th>
                                            <th className="px-4 py-2 text-left" onClick={() => this.handleOrderBy('last_name')}>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200">
                                    {Object.entries(list).filter((item, key) => showAll || key < 4).map((itemData, i) => {
                                        let item = itemData[1];

                                        return (
                                            <tr key={i}>
                                                <td className="px-4 py-2">
                                                    {item.title}
                                                </td>
                                                <td className="px-4 py-2 flex flex-row gap-4 justify-center items-center">
                                                    {item.type === 'cms_records' && (
                                                        <ModalTrigger
                                                            component={CreateCmsElementRecordModal}
                                                            props={{
                                                                showElementsDropdown: false,
                                                                element_id: item.element_id,
                                                                element_record_id: item.record_id,
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faPencilAlt}
                                                                className="text-gray-400 hover:text-gray-600 transition duration-200"
                                                            />
                                                        </ModalTrigger>
                                                    )}
                                                    {item.type === 'timesheet_query' && (
                                                        <>
                                                            <ModalTrigger
                                                                component={CreateTimesheetModal}
                                                                props={{
                                                                    record: item.record,
                                                                    admin: User.data.is_admin
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faPencilAlt}
                                                                    className="text-gray-400 hover:text-gray-600 transition duration-200"
                                                                />
                                                            </ModalTrigger>

                                                            <ModalTrigger
                                                                component={QueryTimesheetModal}
                                                                props={{
                                                                    timesheet: item.record,
                                                                    admin: User.data.is_admin
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faQuestion}
                                                                    className="text-gray-400 hover:text-gray-600 transition duration-200"
                                                                />
                                                            </ModalTrigger>
                                                        </>
                                                    )}
                                                    {item.type === 'timesheet_missing' && (
                                                        <ModalTrigger
                                                            component={CreateTimesheetModal}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faPlus}
                                                                className="text-gray-400 hover:text-gray-600 transition duration-200"
                                                            />
                                                        </ModalTrigger>
                                                    )}
                                                    {item.type === 'holiday_approval' && (
                                                        <>
                                                            <ModalTrigger
                                                                component={ShowTimesheetModal}
                                                                props={{
                                                                    timesheet_id: item.record.id,
                                                                    admin: User.data.is_admin
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                    className="text-gray-400 hover:text-gray-600 transition duration-200"
                                                                />
                                                            </ModalTrigger>
                                                            <ModalTrigger
                                                                component={ApproveHoliday}
                                                                props={{
                                                                    timesheet: item.record,
                                                                    admin: User.data.is_admin
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faPencilAlt}
                                                                    className="text-gray-400 hover:text-gray-600 transition duration-200"
                                                                />
                                                            </ModalTrigger>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>

                                <div
                                    className="my-4 cursor-pointer text-center text-gray-400 hover:text-gray-600 transition duration-200"
                                    onClick={this.handleToggleShowAll}
                                >
                                    Show {showAll ? 'Less' : 'More'}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}
