import ApiBase from '../ApiBase';

class Groups extends ApiBase {
    route = '/networking/groups';

    /**
     * @param {string} name
     * @returns {Promise<*>}
     */
    createGroup = (name) => {
        return this.post(this.route, name);
    };

    /**
     * @param {string} id
     * @param {string} name
     * @returns {Promise<*>}
     */
    updateGroup = (id, name) => {
        return this.put ( this.route + '/group/' + id, name );
    }

    /**
     * @param {string} id
     * @returns {Promise<*>}
     */
    show = (id) => {
        return this.get ( this.route + '/group/' + id );
    }

}

export default new Groups;

