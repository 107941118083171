
import React from "react";

import { Modal,ModalBody, } from "Components/Modal";
import { FormHandler, Toggle, Input, DatePicker, Textarea } from "Components/Form";
import GroupsApi from 'Services/Api/Networking/Groups';
import { PrimaryButton } from "Components/Button/index";
import AdminUsersApi from "Services/Api/Admin/HumanResources/Users";
import { Select } from "Components/Form";
import { Label } from "Components/Form";
import { ModalHeader } from "Components/Modal";
import { FileUpload } from "Components/Form";

class UpdateGroupForm extends React.Component {
    //set the form state
    state = {
        form: {
            name: '',
        }
    };

    success = 'Group created successfully';


    //get the group data
    getGroupData = async () => {
        const { selectedGroupId, setForm } = this.props;
        const response = await GroupsApi.show(selectedGroupId);
        if (response.success) {


            //users not to be linked with user_ids
            const users = response?.data?.users;
            if (!users) return;
            const user_ids = users.map(user => {
                return {
                    value: user.id,
                    label: user.full_name
                }
            } );

            await  setForm({
                name: response?.data?.name,
                all_users: response?.data?.all_users,
                user_ids: user_ids,
                avatar_path: response?.data?.avatar_path
            });
        }
    };

    //update the group
    updateGroup = async () => {
        const { form } = this.state;
        const { selectedGroupId, setErrors } = this.props;

        const response = await GroupsApi.updateGroup(selectedGroupId, form);
        if (response.success) {
            this.props.history.push('/networking');
        }

        if (response.error) {
            this.props.setErrors(response.errors);
        }
    };

    componentDidMount() {
        this.getGroupData();
    }


    /**
     * @method render
     * @type {{form: {name: string}, handleInput: function, handleSubmit: function}}
     */
    render () {
        const { form, handleInput, handleSubmit, selectedGroupId, onClose, getFieldError } = this.props;
        return (
            <Modal>
                <ModalHeader onClose={onClose}>
                    <h2 className="text-2xl font-semibold">Update Group { form?.name }</h2>
                </ModalHeader>
                <form onSubmit={ ( e ) => handleSubmit ( e, () =>  GroupsApi.updateGroup(selectedGroupId, form), onClose) }>
                    <ModalBody>
                        <div className={ 'mb-6' }>
                            <Input
                                label="Name"
                                id="name"
                                value={ form?.name }
                                onChange={ ( v ) => handleInput ( 'name', v ) }
                                error={ getFieldError ( 'name' ) }
                            />
                        </div>

                        <div className={ 'mb-6' }>
                            <Label label={ "Add All Users" } />

                            <Toggle
                                id="all_users"
                                value={ form?.all_users }
                                onChange={ ( v ) => handleInput ( 'all_users', v ) }
                                error={ getFieldError ( 'all_users' ) }
                            />
                        </div>

                        { form?.all_users !== true && (
                            <div className={ 'mb-6' }>
                                <Select
                                    containerClassName="mb-6"
                                    id="user_ids"
                                    value={ form?.user_ids }
                                    onChange={ ( v ) => handleInput ( 'user_ids', v ) }
                                    isAsync
                                    isMulti
                                    searchCallback={ ( data ) => AdminUsersApi.get ( null, data ) }
                                    searchLabelKey="full_name"
                                    allowNull={ !form?.all_users }
                                    placeholder="Select a user ..."
                                    error={ getFieldError ( 'user_ids' ) }
                                    options={ form?.user_ids }
                                />
                            </div>
                        ) }

                        <div className={ 'mb-6 text-center flex flex-col justify-center' }>
                            <Label label="Group Avatar" />
                            <FileUpload
                                containerClassName="flex flex-col justify-center items-center gap-4"
                                value={ form?.avatar_path }
                                onChange={ v => handleInput ( 'avatar_path', v ) }
                                accept="image/*"
                                placeholder="Add"
                                error={ getFieldError ( 'avatar_path' ) }
                                fallback={ form?.avatar_path }
                            />
                        </div>

                        <PrimaryButton
                            type="submit"
                            className="mt-4"
                            text={ 'Save' }
                        >
                            Create Group
                        </PrimaryButton>
                    </ModalBody>
                </form>
        </Modal>
        );
    }
}

export default FormHandler ( UpdateGroupForm );
