import React from 'react';
import {Redirect} from 'react-router-dom';

import User from 'Services/User';

/**
 * @function Group
 * @param {*|JSX.Element} component
 * @param {string[]} parameters
 * @return {*|JSX.Element}
 * @constructor
 */
const Group = ({component, parameters}) => {
    // Ensure parameters is an array
    const groups = Array.isArray(parameters) ? parameters : parameters.split(',');

    // Get user groups
    const userGroups = User.data.groups.map(group => group.name);

    // Check if user has access to at least one group
    const hasAccess = groups.some(group => userGroups.includes(group));



    return (hasAccess || User.isAdmin) ? component : <Redirect to="/" />;
};

export default Group;
