
import React from "react";

import { Modal,ModalBody, } from "Components/Modal";
import { FormHandler, Toggle, Input, DatePicker, Textarea } from "Components/Form";
import GroupsApi from 'Services/Api/Networking/Groups';
import { PrimaryButton } from "Components/Button/index";
import AdminUsersApi from "Services/Api/Admin/HumanResources/Users";
import { Select } from "Components/Form";
import { Label } from "Components/Form";
import { ModalHeader } from "Components/Modal";
import { getFallbackAvatarUrl } from "Services/AuthHelpers";
import { User } from "Services";
import { FileUpload } from "Components/Form";

class CreateGroupForm extends React.Component {
    //set the form state
    state = {
        form: {
            name: '',
            all_users: false,
            user_ids: [],
            avatar_path: ''
        }
    };

    success = 'Group created successfully';


    /**
     * @method render
     * @type {{form: {name: string}, handleInput: function, handleSubmit: function}}
     */
    render () {
        const { form, handleInput, handleSubmit, onClose, getFieldError } = this.props;
        return (
            <Modal>
                <ModalHeader onClose={ onClose }>
                    Create Group
                </ModalHeader>
                <form onSubmit={ ( e ) => handleSubmit ( e, GroupsApi.createGroup, this.success, 'create' ) }>
                    <ModalBody>
                        <div className={ 'mb-6' }>
                            <Input
                                label="Name"
                                id="name"
                                value={ form?.name }
                                onChange={ ( v ) => handleInput ( 'name', v ) }
                                error={getFieldError('name')}
                            />
                        </div>

                        <div className={ 'mb-6' }>
                            <Label label={"Add All Users"} />

                            <Toggle
                                id="all_users"
                                value={ form?.all_users }
                                onChange={ ( v ) => handleInput ( 'all_users', v ) }
                                error={getFieldError('all_users')}
                            />
                        </div>

                        { form?.all_users !== true && (
                            <div className={ 'mb-6' }>
                                <Select
                                    containerClassName="mb-6"
                                    id="user_ids"
                                    value={ form?.user_ids }
                                    onChange={ ( v ) => handleInput ( 'user_ids', v ) }
                                    isAsync
                                    isMulti
                                    searchCallback={ ( data ) => AdminUsersApi.get ( null, data ) }
                                    searchLabelKey="full_name"
                                    allowNull={ false }
                                    placeholder="Select a user ..."
                                    label="Select Users"
                                    error={getFieldError('user_ids')}
                                />
                            </div>
                        ) }
                        <div className={ 'mb-6 text-center flex flex-col justify-center' }>
                            <Label label="Group Avatar" />
                            <FileUpload
                                containerClassName="flex flex-col justify-center items-center gap-4"
                                value={form.avatar_path}
                                onChange={v => handleInput('avatar_path', v)}
                                accept="image/*"
                                placeholder="Add"
                                error={getFieldError('avatar_path')}
                            />
                        </div>
                        <PrimaryButton
                            type="submit"
                            className="mt-4"
                            text={ 'Create Group' }
                        >
                            Create Group
                        </PrimaryButton>
                    </ModalBody>
                </form>
        </Modal>
        );
    }
}

export default FormHandler ( CreateGroupForm );
