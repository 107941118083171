import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';

import Authenticated from 'Components/Layouts/Authenticated';
import TimesheetsCalendar from 'Components/HumanResources/Timesheets/Calendar';
import SubscriptionCalendarLink from "Components/HumanResources/Timesheets/SubscriptionCalendarLink";
import { PrimaryButton } from 'Components/Button';
import { ModalTrigger } from 'Components/Modal';
import CreateCmsElementRecordModal from 'Components/Partials/Modals/CreateCmsElementRecordModal';

import ToDoListTable from 'Pages/ToDoList/IndexTable';
import MedicationTakenStore from 'Pages/ServiceUsers/MedicationsTaken/store';

import { translation } from 'Services/TranslationHelpers';
import User from 'Services/User';

import Alerts from './Alerts';
import ActiveServiceUserButton from './ActiveServiceUserButton';

export default class Dashboard extends React.Component {
    /**
     * @method render
     * @return {*}
     */
    render () {
        if (User.belongsToGroup('Staff')) {
            return this.renderStaffDashboard();
        } else if (User.belongsToGroup('Service User')) {
            return this.renderServiceUserDashboard();
        } else if (User.belongsToGroup('Service User Family')) {
            return this.renderServiceUserFamilyDashboard();
        } else {
            return <Authenticated title="Dashboard" titleIcon={faHome} />;
        }
    }

    /**
     * @method renderStaffDashboard
     * @return {*}
     */
    renderStaffDashboard() {
        return (
            <Authenticated title="Dashboard" titleIcon={faHome}>
                <Alerts />

                <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
                    <div>
                        <h3 className="font-league-spartan font-2xl mb-2">
                            {translation('to_do_list', 'title')}
                        </h3>

                        <div className="bg-white">
                            <ToDoListTable />
                        </div>
                    </div>

                    <div className="flex flex-col justify-start items-center gap-4">
                        <ModalTrigger
                            component={CreateCmsElementRecordModal}
                            props={{
                                showElementsDropdown: true,
                                element_id: null,
                                element_record_id: null,
                                service_user_id: null,
                                attach_to_service_user: true
                            }}
                        >
                            <PrimaryButton
                                className="font-league-spartan font-bold md:text-xl"
                                text={translation('narratives', 'create')}
                            />
                        </ModalTrigger>

                        <ModalTrigger
                            component={MedicationTakenStore}
                            props={{

                                date: new Date()
                            }}
                        >
                            <PrimaryButton
                                className="font-league-spartan font-bold md:text-xl"
                                text={translation('service_users', 'record_medications_taken')}
                            />
                        </ModalTrigger>

                        {/*<ModalTrigger*/}
                        {/*    component={CovidTestEntryStore}*/}
                        {/*>*/}
                        {/*    <PrimaryButton*/}
                        {/*        className="font-league-spartan font-bold md:text-xl"*/}
                        {/*        text="Submit A Covid Test Result"*/}
                        {/*    />*/}
                        {/*</ModalTrigger>*/}

                        <ActiveServiceUserButton />

                        <ModalTrigger
                            component={SubscriptionCalendarLink}
                            admin={false}
                        >
                            <PrimaryButton
                                className="font-league-spartan font-bold md:text-xl"
                                text={translation('subscription-calendar-link', 'title-my')}
                            />
                        </ModalTrigger>
                    </div>

                    <div className="xl:col-span-2 bg-white">
                        <TimesheetsCalendar
                            title='My HR Calendar'
                            admin={false}
                            layout="dashboard"
                        />
                    </div>
                </div>
            </Authenticated>
        );
    }

    /**
     * @method renderServiceUserDashboard
     * @return {*}
     */
    renderServiceUserDashboard() {
        return (
            <Authenticated title="Dashboard" titleIcon={faHome}>
            </Authenticated>
        );
    }

    /**
     * @method renderServiceUserDashboard
     * @return {*}
     */
    renderServiceUserFamilyDashboard() {
        return (
            <Authenticated title="Dashboard" titleIcon={faHome}>
            </Authenticated>
        );
    }
}
