import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

import {PaginationScroll} from 'Components/Partials';
import {Select} from 'Components/Form';
import {ProfilePic, ActiveUsersService, handleMessagePreview, isSameUser, isSameUserCheck} from 'Components/Networking';

import {Event} from 'Services';
import UsersApi from 'Services/Api/Networking/Users';
import GroupsApi from 'Services/Api/Networking/Groups';
import ToApi from 'Services/Api/Networking/To';
import {translation} from 'Services/TranslationHelpers';
import {mergeObjectsIntoPaginationData} from 'Services/BaseHelpers';
import { User } from "Services";
import { ModalTrigger } from "Components/Modal/index";
import UpdateGroupForm from "Components/Networking/UpdateFormGroup";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import CreateGroupForm from "Components/Networking/CreateFormGroup";
import { PrimaryButton } from "Components/Button/index";

export default class Users extends React.Component {
    /**
     * @method constructor
     */
    constructor() {
        super();
        this.selectedUserRef = React.createRef();
    }

    /**
     * @var state
     */
    state = {
        activeUsers: [],
        activeUserIds: [],
        usersOrGroups: 'users',
        users: null,
    }

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        const {selected_user} = this.props;

        this.handleActiveUsers();

        if (selected_user) {
            this.handleSelectedUser(selected_user);
        }

        //if selectedGroupId is set, then we need to load the group

        this.setState(
            {
                usersOrGroups: this.props.selectedGroupId ? 'groups' : 'users'
            }
        );
    }

    /**
     * @method componentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {selected_user} = this.props;

        if (selected_user && prevProps.selected_user !== selected_user) {
            this.scrollToSelectedUser();

            this.handleSelectedUser(selected_user);
        }
    }

    /**
     * @method componentDidUpdate
     * @param {object} selected_user
     */
    handleSelectedUser = (selected_user) => {
        /*
        TODO
        PaginationScroll resets the data when loading the first page therefore we have to wait
        until after that page has loaded to add this user. Used a hacky setTimeout solution.
         */
        setTimeout(() => {
            this.handleNewUser(selected_user);
        }, 500);
    }

    /**
     * @method handleActiveUsers
     */
    handleActiveUsers = () => {
        this.setState({
            activeUsers: ActiveUsersService.getActiveUsers(),
            activeUserIds: ActiveUsersService.getActiveUserIds()
        });

        Event.on('networking-active-users', () => {
            this.setState({
                activeUsers: ActiveUsersService.getActiveUsers(),
                activeUserIds: ActiveUsersService.getActiveUserIds()
            });
        });
    }

    /**
     * @method handleNewUser
     * @param {object} user
     * @return {Promise<void>}
     */
    handleNewUser = (user) => {
        const {usersOrGroups, users} = this.state;

        let newUsersOrGroups = user.type === 'App\\Models\\User' ? 'users' : 'groups';

        // If user is not yet in the list then add it.
        // If changing usersOrGroups then reset users to empty.
        if (users.data.filter(o => isSameUser(o, user)).length === 0) {
            return this.setState({
                users: usersOrGroups === newUsersOrGroups ?
                    mergeObjectsIntoPaginationData(users, [user], false, isSameUserCheck()) :
                    null,
                usersOrGroups: newUsersOrGroups
            });
        }
    }

    /**
     * @method scrollToSelectedUser
     */
    scrollToSelectedUser = () => {
        if (this.selectedUserRef.current !== null) {
            this.selectedUserRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }

    /**
     * @method getApiCall
     * @param {boolean} useData
     * @return {object}
     */
    getApiCall = (useData) => {
        const {usersOrGroups} = this.state;

        if (useData) {
            if (usersOrGroups === 'users') {
                return (data) => UsersApi.get(null, data);
            } else if (usersOrGroups === 'groups') {
                return (data) => GroupsApi.get(null, data);
            }
        } else {
            if (usersOrGroups === 'users') {
                return (page, search) => UsersApi.get(null, {page, search});
            } else if (usersOrGroups === 'groups') {
                return (page, search) => GroupsApi.get(null, {page, search});
            }
        }
    }

    /**
     * @method setSearchSelectedUser
     * @param {string} id
     */
    setSearchSelectedUser = async (id) => {
        const {usersOrGroups} = this.state;
        const {setSelectedUser} = this.props;

        const response = await ToApi.get(id, {
            message_to_type: usersOrGroups === 'users' ? 'App\\Models\\User' : 'App\\Models\\NetworkingGroup'
        });

        setSelectedUser(response.data.data);
    }

    /**
     * @method sortUsers
     * @param {object} user_a
     * @param {object} user_b
     * @return {integer}
     */
    sortUsers = (user_a, user_b) => {
        const {activeUserIds} = this.state;

        // If both active users then leave as is.
        if (user_a.type === "App\\Models\\User" && activeUserIds.indexOf(user_a.id) > -1
            && user_b.type === "App\\Models\\User" && activeUserIds.indexOf(user_b.id) > -1) {
            return 1;
        }

        // Put active users at the top.
        if (user_a.type === "App\\Models\\User" && activeUserIds.indexOf(user_a.id) > -1) {
            return -1;
        }

        return 1;
    }

    /**
     * @method render
     * @return {*}
     */
    render() {
        const {useGroups} = this.props;

        return (
            <div className="h-full flex flex-col border border-networking-primary p-2">
                <h1 className="h1 uppercase mx-auto lg:mx-1 my-6 text-lg font-bold">
                    {translation('networking_frontend', 'networking')}
                </h1>

                {useGroups && this.renderUsersOrGroupsToggle()}
                {useGroups && User.data.is_admin &&
                    <ModalTrigger
                        component={CreateGroupForm}
                        props={{
                            title: 'Create Group',
                        }}

                    >
                        <PrimaryButton
                            className="w-full mb-6"
                            text="Create Group"
                        />
                    </ModalTrigger>
                }
                {this.renderSearchBar()}
                {this.renderUsers()}
            </div>
        )
    }

    /**
     * @method renderUsersOrGroupsToggle
     * @return {*}
     */
    renderUsersOrGroupsToggle() {
        const {usersOrGroups} = this.state;

        let options = ['users', 'groups'];

        return (
            <div className="grid grid-cols-2 text-center border border-2 border-red-400 cursor-pointer mb-4">
                {options.map((option, i) => (
                    <div
                        className={`p-4 ${usersOrGroups === option ? 'bg-red-400 text-white' : ''}`}
                        onClick={() => {
                            if (usersOrGroups !== option) {
                                this.setState({
                                    usersOrGroups: option,
                                    users: null
                                })
                            }
                        }}
                        key={i}
                    >
                        {translation('networking_frontend', option)}
                    </div>
                ))}
            </div>
        );
    }

    /**
     * @method renderSearchBar
     * @return {*}
     */
    renderSearchBar() {
        const {setSelectedUser} = this.props;
        const {usersOrGroups} = this.state;

        let apiCall = this.getApiCall(true);

        return (
            <div className="w-full px-2 mb-2">
                <div className="mb-1 p-1 border border-networking-primary bg-networking-primary-text cursor-pointer">
                    <div className="flex items-center">
                        <FontAwesomeIcon
                            icon={faSearch}
                            size="1x"
                            className="text-networking-secondary-text mx-2"
                        />

                        <Select
                            containerClassName="w-full"
                            onChange={(v) => this.setSearchSelectedUser(v)}
                            searchCallback={apiCall}
                            searchLabelKey="name"
                            placeholder={translation('networking_frontend', 'search_name')}
                            isAsync
                            key={usersOrGroups}
                        />
                    </div>
                </div>
            </div>
        );
    }

    /**
     * @method renderUsers
     * @return {*}
     */
    renderUsers() {
        const {activeUsers, usersOrGroups, users} = this.state;

        let apiCall = this.getApiCall(false);

        // Add the activeUsers to the users displayed.
        if (usersOrGroups === 'users') {
            var allUsers = mergeObjectsIntoPaginationData(users, activeUsers, false, isSameUserCheck());
        } else if (usersOrGroups === 'groups') {
            var allUsers = users;
        }

        return (
            <PaginationScroll
                scrollDown={true}
                apiCall={apiCall}
                updateData={(data) => this.setState({users: data})}
                data={users}
                containerClassName="px-2"
                key={usersOrGroups}
                sameObjectCheck={isSameUserCheck()}
            >
                {allUsers && allUsers.data.length === 0 &&
                    <div className="p-4 font-bold">
                        {translation('networking_frontend', 'check_back')}
                    </div>
                }

                {allUsers && allUsers.data.sort((a, b) => {
                    return this.sortUsers(a, b)
                }).map((user, i) => (this.renderUser(user)))}
            </PaginationScroll>
        );
    }

    /**
     * @method renderUser
     * @param {object} user
     * @return {*}
     */
    renderUser(user) {
        const {activeUserIds} = this.state;
        const {selected_user, setSelectedUser} = this.props;

        let is_selected_user = (selected_user && isSameUser(selected_user, user));

        return (
            <div className={"flex flex-row w-full"}>
                <div
                    className={`
                        mt-1 mb-1 first:mt-0 last:mb-0 p-4 border-l-8
                        relative cursor-pointer overflow-visible
                        w-full
                        flex flex-col xl:flex-row justify-start items-center gap-4
                        ${is_selected_user ?
                            'bg-networking-active text-networking-primary-text' :
                            'bg-networking-secondary'}
                        ${(activeUserIds.indexOf(user.id) > -1 ?
                            'border-networking-active' :
                            'border-networking-inactive')}
                        hover:bg-networking-active hover:text-networking-primary-text
                    `}
                    onClick={() => setSelectedUser(user)}
                    ref={is_selected_user ? this.selectedUserRef : null}
                    key={user.type + '_' + user.id}
                >
                    <ProfilePic user={user}/>

                    <div>
                        <p className="font-bold text-center xl:text-left text-networking-secondary-text">
                            {user.name}
                        </p>

                        {user.subtitle && user.subtitle !== '' &&
                            <p className="text-center xl:text-left text-networking-secondary-text text-sm">
                                {user.subtitle}
                            </p>
                        }
                    </div>

                </div>
                {(User.data.is_admin && this.state.usersOrGroups === 'groups') && (
                    <div
                        className={"text-networking-secondary-text my-auto mx-2 cursor-pointer flex justify-center items-center"}
                    >
                        <ModalTrigger
                            component={UpdateGroupForm}
                            props={{
                                title: 'Update Group',
                                selectedGroupId: user.id,
                            }}

                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                                size="1x"
                                className="text-networking-secondary-text my-auto mx-2 cursor-pointer flex justify-center items-center"
                            />
                        </ModalTrigger>

                    </div>
                )}
        </div>
        );
    }
}
