import ApiBase from '../../ApiBase';

class Record extends ApiBase {
    route = '/cms/elements/records';

    /**
     * @method storeRecord
     * @param {object} data
     * @param {object} fields
     * @return {Promise<*>}
     */
    storeRecord = async (data, fields) => {
        data = await this.uploadRecordFields(data, fields);
        return this.post(this.route, data);
    }

    /**
     * @method storeRecord
     * @param {object} data
     * @param {object} fields
     * @return {Promise<*>}
     */
    getFamily = async (u, data) => {
        return this.get('/service-users-family/cms/elements/records/', data);
    }

    /**
     * @method storeRecord
     * @param {object} data
     * @param {object} fields
     * @return {Promise<*>}
     */
    getFamilyRecord = async (u, data) => {
        return this.get('/service-users-family/cms/elements/records/', data);
    }

    /**
     * @method updateRecord
     * @param {string} id
     * @param {object} data
     * @param {object} fields
     * @return {Promise<*>}
     */
    updateRecord = async (id, data, fields) => {
        data = await this.uploadRecordFields(data, fields);
        return this.patch(this.route + '/' + id, data);
    }

    /**
     * @method updateOrder
     * @param {object} data
     * @return {Promise<*>}
     */
    updateOrder = (data) => {
        return this.patch(this.route + '/update-order', data);
    };

    /**
     * @method uploadRecordFields
     * @param {object} data
     * @param {object} fields
     * @return {object}
     */
    uploadRecordFields = async (data, fields) => {
        for (var key in fields) {
            // skip loop if the property is from prototype
            // https://stackoverflow.com/questions/921789/how-to-loop-through-a-plain-javascript-object-with-the-objects-as-members
            if (!fields.hasOwnProperty(key)) continue;

            let type = fields[key].type;
            let identifier = fields[key].identifier;

            if (!data[identifier]) continue;

            if (type === 'date' || type === 'time') {
                let value = data[identifier];

                // Date object.
                if (typeof value === '[object Date]' || Object.prototype.toString.call(value) === '[object Date]') {
                    var iso_string = value.toISOString();
                }

                // Date serialized.
                if (value?.iso_string) {
                    var iso_string = value.iso_string;
                }

                if (type === 'date') {
                    data[identifier] = iso_string.split('T')[0];
                }
                else if (type === 'time') {
                    data[identifier] = iso_string.split('T')[1].split('.')[0];
                }
            }
            else if (fields[key].type === 'image_upload') {
                for (var key2 in data[identifier]) {
                    if ('new_file' in data[identifier][key2] && data[identifier][key2]['new_file']) {
                        const upload = await this.uploadFile(data[identifier][key2].new_file);

                        if (upload.status !== 200) {
                            return upload;
                        }

                        data[identifier][key2].new_file = null;
                        data[identifier][key2].new_file_key = upload.tempKey;
                    }
                }
            }
        }

        return data;
    }
}

export default new Record();
